import './SettingsCentersAddModal.less'

import { Form, Input } from 'antd'
import React, { useState } from 'react'

import { useScopedIntl } from '../../../../hooks'
import { addCenter, availableCountries } from '../../../../requests'
import { maxTitleLengthLong, validateCenterAbbreviation, validateRequired } from '../../../../validation'
import { DatacFormItem, DatacMessage, DatacModal, DatacSelect } from '../../../common'

const maxAbbreviationLength = 10

interface FormData {
  name: string
  country: string
  abbreviation: string
}

interface Props {
  onClose: () => void
  isOpened: boolean
  onCenterAdded: () => void
}

export const SettingsCentersAddModal: React.FC<Props> = ({ isOpened, onClose, onCenterAdded }) => {
  const intl = useScopedIntl('')
  const intlModal = useScopedIntl('settings.centers.add_modal')
  const [formInstance] = Form.useForm()
  const [isAddingCenter, setIsAddingCenter] = useState(false)
  const [isAbbreviationTaken, setIsAbbreviationTaken] = useState(false)

  const countryOptions = Object.keys(availableCountries).map(value => ({
    value,
    label: availableCountries[value]
  }))

  const onSubmit = ({ name, country, abbreviation }: FormData) => {
    setIsAddingCenter(true)
    addCenter(
      { name, country, abbreviation },
      {
        onSuccess: () => {
          setIsAddingCenter(false)
          onClose()
          onCenterAdded()
        },
        onRequestError: code => DatacMessage.genericError(intl, code),
        onAbbreviationTaken: () => setIsAbbreviationTaken(true),
        onError: () => setIsAddingCenter(false)
      }
    )
  }

  const onAbbreviationChange = () => {
    if (isAbbreviationTaken) setIsAbbreviationTaken(false)
  }

  return (
    <DatacModal
      isOpened={isOpened}
      onClose={onClose}
      afterClose={() => formInstance.resetFields()}
      title={intlModal('title')}
      submitLabel={intlModal('submit')}
      onSubmit={() => formInstance.submit()}
      loading={isAddingCenter}
      destroyOnClose
    >
      <Form
        form={formInstance}
        name="settings-centers-add-form"
        className="settings-centers-add-modal__form"
        onFinish={onSubmit}
      >
        <DatacFormItem
          name="name"
          label={intlModal('name.label')}
          validate={validateRequired(intlModal('name.validation'))}
        >
          <Input
            className="settings-centers-add-modal__input"
            placeholder={intlModal('name.placeholder')}
            maxLength={maxTitleLengthLong}
          />
        </DatacFormItem>

        <DatacFormItem
          name="abbreviation"
          label={intlModal('abbreviation.label')}
          description={intlModal('abbreviation.description')}
          validate={[
            validateRequired(intlModal('abbreviation.validation.required')),
            validateCenterAbbreviation(intlModal('abbreviation.validation.characters'))
          ]}
          error={isAbbreviationTaken ? intlModal('abbreviation.validation.taken') : undefined}
        >
          <Input
            className="settings-centers-add-modal__input"
            placeholder={intlModal('abbreviation.placeholder')}
            maxLength={maxAbbreviationLength}
            onChange={onAbbreviationChange}
          />
        </DatacFormItem>

        <DatacFormItem
          name="country"
          label={intlModal('country.label')}
          validate={validateRequired(intlModal('country.validation'))}
        >
          <DatacSelect showSearch options={countryOptions} placeholder={intlModal('country.placeholder')} />
        </DatacFormItem>
      </Form>
    </DatacModal>
  )
}
