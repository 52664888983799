import './SettingsCentersArchiveModal.less'

import React, { useState } from 'react'

import { useScopedIntl } from '../../../../hooks'
import { CenterData, archiveCenter } from '../../../../requests'
import { DatacMessage, DatacModal } from '../../../common'

interface Props {
  isOpened: boolean
  onClose: () => void
  center: CenterData
  onCenterArchived: () => void
}

export const SettingsCentersArchiveModal: React.FC<Props> = ({ isOpened, onClose, center, onCenterArchived }) => {
  const intl = useScopedIntl('')
  const intlModal = useScopedIntl('settings.centers.archive_modal')
  const [isDeleting, setIsDeleting] = useState(false)

  const onSubmit = () => {
    setIsDeleting(true)
    archiveCenter(
      { centerId: center.id },
      {
        onSuccess: () => {
          setIsDeleting(false)
          onClose()
          onCenterArchived()
        },
        onError: () => setIsDeleting(false),
        onRequestError: code => DatacMessage.genericError(intl, code)
      }
    )
  }

  return (
    <DatacModal
      title={intlModal('title')}
      isOpened={isOpened}
      onClose={onClose}
      onSubmit={onSubmit}
      submitLabel={intl('common.archive')}
      loading={isDeleting}
    >
      <div className="settings-centers-archive-modal__body">{intlModal('message', { center: center?.name })}</div>
    </DatacModal>
  )
}
